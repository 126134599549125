.case
    position relative
    display flex
    overflow visible

    &::before
        content ''
        overflow hidden
        display block

.case__videos
    position fixed
    bottom 0
    top 150px
    z-index -1
    +portrait()
        display none

.case__content
    z-index: 100
    max-width 660pr
    margin 114pr 310pr 114pr auto
    padding 8pr 60pr 53pr 50pr
    font-size 18pr
    line-height 26pr
    color black
    background white
    +portrait()
        margin 0
        font-size 16pr
        line-height 23pr
        padding-bottom 150pr
    p
        margin 14pr 0 10pr

.case__title
    max-width 400pr
    margin-bottom 12pr
    font-size 30pr
    line-height 35pr
    font-weight 500

.case__slug
    font-size 15pr
    line-height 23pr

.case__controls
    position fixed
    right 0
    width 310pr

    +portrait()
        display none

.case__counter
    display block
    margin 70pr 75pr 21pr
    font-size 11pr
    line-height 23pr
    text-align right


.case__close
    @extends $btn-reset
    display block
    width 80pr
    height 80pr
    margin 0 60pr 198pr auto
    background white
    background-image url('/static/img/close.svg')
    background-repeat no-repeat
    background-size 36pr 36pr
    background-position center
    +portrait()
        position fixed
        z-index 1005
        margin 0
        right 85pr
        top 15pr

.case__pagination
    display flex
    height 80pr
    justify-content center
    align-items center
    background black

.case__arrow
    width 50pr
    height 10pr
    margin 0 25pr
    background-repeat no-repeat

    &--prev
        background-image url('/static/img/prev.svg')

    &--next
        background-image url('/static/img/next.svg')

.case__close
  display none

.page--case
  .case__close
    display block
    +portrait()
        background-color #000000
