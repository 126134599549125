// ==========
// Project global styles
// ==========

// box-sizing reset
*, *:after, *:before
	box-sizing: inherit
	-webkit-backface-visibility: hidden;
	outline none


html
	box-sizing border-box
	background-color html_bg
	font-size 2.22vh

	@media only screen and (orientation: portrait)
		font-size 4vw
	@media only screen and (max-width: 1023px) and (orientation: landscape)
		font-size 2.2vw
body
	font-family body_font
	color body_color
	// safari smooth font. prefix mandatory.
	-webkit-font-smoothing antialiased
	// prevent fs scale on ios safari
	text-size-adjust 100%
	font-size: 15pr;
	line-height: 23pr;

.preload * {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
}
// ==========
// Abstract class
// ==========

.main
	padding-top 150pr
	position relative
	z-index 2
	+portrait()
		padding-top 111pr

.button--gold
	span
		width 318pr
		height 80pr
		display inline-block
		background #D3AC6B
		line-height 80pr
		text-align center
		font-size 15pr
	&.hidden
		max-width 0
		transition 1s 0s

.button--animated
	overflow hidden
	max-height 0
	transition 1s 2s
.button--close
	@extends $btn-reset
	display block
	width 80pr
	height 80pr
	background white
	background-image url('/static/img/close.svg')
	background-repeat no-repeat
	background-size 36pr 36pr
	background-position center
	&.hidden
		max-width 0
		transition 1s 0s
.animated
	.button--animated
		max-height 80pr
.page--index
	.main
		padding 0

a, button
	cursor pointer
	&:focus
		outline none
.mobile-only
	display none
	+portrait()
		display block


// ==========
// Phone
// ==========

.phone_button
	position fixed
	right 75pr
	bottom 24pr
	z-index 10000
	display flex
	justify-content center
	align-items center
	width 56pr
	height 56pr
	border none 
	border-radius 50%
	background #ffffff
	cursor pointer
	transition 0.2s
	transition-property background-color

	&:hover
		background #000000

	+portrait()
		right 30pr
		bottom 81pr

@keyframes phonemodalbox
	0%
		transform translateY(100vh)
	100%
		transform translateY(0)

@keyframes phonemodal
	0%
		opacity 0
	100%
		opacity 1

.phone_modal
	position fixed
	bottom 24pr
	right 150pr
	z-index 20000
	display none
	height 54pr
	justify-content center
	align-items center
	+portrait()
		top 0
		left 0
		bottom 0
		width 100%
		height 100vh
		// padding 80pr 20pr 0
		// align-items center
		background #000000
	
	&--open
		animation phonemodal
		animation-duration 0.6s
		animation-fill-mode forwards

	&--desc.phone_modal--open
		display flex
		+portrait()
			display none


	&--mob.phone_modal--open
		display none
		+portrait()
			display flex

.phone_modal__box
	height 100%
	padding 10pr 20pr
	background #ffffff
	color #000000

	+portrait()
		width 100%
		height auto
		margin-top auto
		padding 20pr 30pr 40pr
		animation phonemodalbox
		animation-duration 1s
		animation-fill-mode forwards
		animation-timing-function ease-in-out


.phone_modal__title
	display none
	margin 0 0 19pr
	font-size 15pr
	font-weight normal
	line-height 23pr
	+portrait()
		display block
		margin-bottom 10pr

.phone_modal__num
	display block
	width 100%
	font-size 30pr
	font-weight 500
	line-height 35pr
	+portrait()
		font-size 20pr
		line-height 25pr

.phone_modal__text
	margin 10pr 0 40pr
	font-size 15pr
	line-height 23pr

.phone_modal__btns
	display none
	margin-top 20pr
	justify-content space-between
	align-items center
	+portrait()
		display flex
		margin-top 30pr

.phone_modal__close,
.phone_modal__tel
	display flex
	justify-content center
	align-items center
	width 48%
	height 41pr
	font-size 15pr
	font-weight normal
	text-decoration none
	color #000000
	border none
	border 1pr solid #D3AC6B
	border-radius 20pr
	background none


.phone_modal__close
	background #ffffff
	transition 0.2s
	transition-property background-color

	&:hover
		background #D3AC6B

.phone_modal__tel
	display none
	background #D3AC6B
	color #ffffff
	+portrait()
		display flex

.mob-anhord
	display none
	+portrait()
		display block
