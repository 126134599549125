.service__videos
  position fixed
  bottom 0
  top 150pr
  right 0;
  z-index -1
  width 420pr
  +portrait()
    display none
  
  .slide__video
    width 100%

.service_page__content
  position relative
  min-height calc(100vh - 200pr)
  margin-top 50pr
  margin-right 420pr
  padding 47pr 58pr 128pr
  background #000000
  +portrait()
    margin 0
    padding 24pr 30pr 158pr

.service_page__pagination
  position absolute
  left 59pr
  bottom 34pr
  +portrait()
    display none

.service_page__title
  margin 0 0 24pr
  font-size 30pr
  font-weight 500
  line-height 35pr
  +portrait()
    margin-bottom 20pr
    font-size 20pr
    line-height 25pr

.service_page__desc
  margin 0
  font-size 18pr
  line-height 26pr
  +portrait()
    font-size 16pr
    line-height 23pr

.service_page__close
  @extends $btn-reset
  position absolute
  top 0
  right -40pr
  display none
  width 80pr
  height 80pr
  background white
  background-image url('/static/img/close.svg')
  background-repeat no-repeat
  background-size 36pr 36pr
  background-position center
  +portrait()
    position fixed
    z-index 1005
    margin 0
    right 85pr
    top 15pr

.page--service
  .service_page__close
    display block
    +portrait()
      background-color #000000