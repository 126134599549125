.all-cases
	height calc(100vh - 150px)
	overflow hidden
	+portrait()
		display flex
		flex-direction column
		height auto
		padding-bottom 115pr
		.slide__text
			order 2
		.slide__video
			order 0
			top 0

		.home_cases__arrows
			width 100%


.home_case__content
	min-height 188pr
	+portrait()
		min-height 10pr
		padding-bottom 20pr
	
	p
		margin-block 22pr
		+portrait()
			margin-bottom 20pr

.home_case__sub_title
	margin-bottom 9pr
	font-size 15pr
	line-height 23pr
	text-transform uppercase
	+portrait()
		font-size 14pr
		line-height 25pr