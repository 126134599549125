.all-counselors
    position relative
    display flex
    overflow visible

    &::before
        content ''
        overflow hidden
        display block
    &__section
        +portrait()
            position relative
            height auto
            padding-bottom 90pr

            &:after
                content ''
                display block
                position absolute
                height calc(100% - 120pr)
                width 100%
                top 120pr
                z-index 0
                bottom 0
                background #000


.all-counselors__videos
    position fixed
    bottom 0
    top 150pr
    width 100%
    z-index -1
    +portrait()
        display none
.all-counselors__slider
    +portrait()
        padding 0 30pr
        margin 0
.all-counselors__list
    display flex
    max-width 960pr
    margin 0 auto 0 160pr
    padding 67pr 80pr 65pr 90pr
    flex-wrap wrap
    list-style none
    background-image linear-gradient(to bottom, transparent 334pr, black 334pr)
    background-attachment fixed
    +portrait()
        display none

.all-counselors__item
    z-index 10
    max-width 360pr

    &.hidden
        display none

    &:nth-child(2n)
        margin-top 200pr
        margin-left 60pr

.all-counselors__item--hide
    display none

.all-counselors__more
    width 360pr
    transform translateY(-80pr)
