$input-reset
	display inline-block
	position relative
	margin 0
	padding 0
	border none
	background-color transparent
	outline none
	font inherit

$btn-reset
	display inline-block
	position relative
	margin 0
	padding 0
	border none
	text-align center
	cursor pointer
	text-decoration none
	outline none
	background-color transparent
	color inherit
	font inherit

$list-reset
	list-style none
	padding 0
	margin 0

$link-reset
	color inherit
	text-decoration none

$h-reset
	margin-top 0
	margin-bottom 0
	font-size inherit
	font-weight normal

$center
	margin-left auto
	margin-right auto

$hide-text
	overflow hidden
	text-indent 100%
	white-space nowrap

$full-center
	position absolute
	top 0
	left 0
	right 0
	bottom 0
	margin auto

// MICRO CLEARFIX
$cf
	&
		*zoom 1
	&:before, &:after
		content ""
		display table
	&:after
		clear both
