@font-face {
	font-family: 'IBM Plex Sans';
	src: url('/static/fonts/IBMPlexSans.eot');
	src: url('/static/fonts/IBMPlexSans.eot?#iefix') format('embedded-opentype'),
		url('/static/fonts/IBMPlexSans.woff2') format('woff2'),
		url('/static/fonts/IBMPlexSans.woff') format('woff'),
		url('/static/fonts/IBMPlexSans.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('/static/fonts/IBMPlexSans-Medium.eot');
	src: url('/static/fonts/IBMPlexSans-Medium.eot?#iefix') format('embedded-opentype'),
		url('/static/fonts/IBMPlexSans-Medium.woff2') format('woff2'),
		url('/static/fonts/IBMPlexSans-Medium.woff') format('woff'),
		url('/static/fonts/IBMPlexSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
