.header
	position fixed
	width 100%
	top 0
	left 0
	display flex
	padding 51pr 58pr
	align-items center
	height 150pr
	z-index 10
	+portrait()
		padding 30pr
		height 114pr
		background #171717
		justify-content space-between

	&__logo
		width 152pr
		margin-right 100pr
		flex-shrink 0
		a
			display inline-block
		+portrait()
			position relative
			width 140pr
			height 52pr
			z-index 1001


		img
			width 100%

	&__lang
		display flex
		align-items center
		+portrait()
			display none


	&__contact
		margin-left auto
		+portrait()
			display none

		a	
			display flex
			justify-content center
			align-items center
			width 169pr
			height 41pr
			border 1px solid #D3AC6B
			border-radius 20pr
			color #fff
			text-decoration none
			transition 0.2s
			transition-property background-color

			&:hover
				background #D3AC6B

	&__menu
		margin-right 0
		margin-left 28pr
		span
			+portrait()
				display none

.lang__switch

	label
		margin 0 21pr
		display block
		padding 5pr
		width 60pr
		height 30pr
		border 1px solid #D3AC6B
		border-radius: 20pr

		input
			display none

		div
			width 100%

			&:after
				content ''
				display inline-block
				width 18pr
				height 18pr
				background #FFF
				border-radius 50%

		input:checked + div
			text-align right


.lang__label.--on
	background-color #D3AC6B

.menu
	position relative
	border none
	background transparent
	color #fff
	font-family body_font
	padding-right 42pr
	z-index 102
	cursor pointer

	+portrait()
		padding 0
		width 25pr
		height 25pr

	&:focus
		outline none


	&:after, &:before
		content ''
		display block
		width 0pr
		height 2pr
		background #fff
		position absolute
		left 60pr
		top 7pr
		transition all 0.3s
		+portrait()
			left 0

	&:after
		top 13pr

.menu__list
	display none
	padding 0
	width 336pr
	height 502pr
	position absolute
	right 0
	top 0
	//display flex
	// align-items flex-end
	flex-direction column
	justify-content flex-end
	z-index 100
	background #000000

	nav
		padding 50pr 50pr 27pr
		+portrait()
			padding 44pr
			padding-left 80pr

	+portrait()
		height 505pr
		width 100%
		font-size: 17pr;
		line-height: 25pr;

.menu__pages
	padding 0
	margin 0 0 10pr
	list-style none

.menu__page_item
	margin-top 25pr
	+portrait()
		margin-top 30pr

.menu__page_link
	color #fff
	text-decoration none

	&:after
		content ''
		display block
		margin-top 12pr
		width 25pr
		height 1px
		background #D3AC6B
		+portrait()
			width 28pr

.menu__links
	display flex
	justify-content flex-start
	align-items center
	padding 21pr 50pr 27pr
	margin 0
	list-style none
	background #000000
	+portrait()
		padding 20pr 31pr 26pr 80pr

.menu__link_item
	padding 0
	margin 0
	margin-right 28pr

.menu__social_img
	width 24pr
	height 24pr

	path
		fill #ffffff
		transition 0.2s

	+portrait()
		width 28pr
		height 28pr

.menu__link_link:hover
	.menu__social_img
		path
			fill #D3AC6B

.menu__300
	display flex
	margin-top 60pr
	span
		width 115pr
		margin-right 12pr
		font-size 14pr
		line-height 18pr
		color #9B9B9B
		+portrait()
			display block
	img
		width 63pr
		height 40pr

.loaded .opened
	.menu
		&:after, &:before
			background #D3AC6B
			width 40pr
			right -5pr
			+portrait()
				width 45pr
				height 1px

		&:before
			top 10pr
			transform rotate(-45deg)

		&:after
			top 10pr
			transform rotate(45deg)

		&__list
			display flex

.loaded
	.menu
		&:before, &:after
			width 25pr