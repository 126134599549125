@keyframes buttonHover
    for i in 0..100
        {1% * i}
            opacity (i/10)
            background-image linear-gradient(to bottom, gold (i * 1%), white (i * 1%))

@keyframes buttonUnhover
    for i in 0..100
        {1% * i}
            opacity (i/10)
            background-image linear-gradient(to bottom, white (i * 1%), gold (i * 1%))

.anim-btn
    @extends $btn-reset
    width 360pr
    height 80pr
    font-size 15rp
    color gold
    background-image linear-gradient(to bottom, gold 0%, white 0%)
    cursor pointer
    transition 0.1s
    transition-property color

.anim-btn--hover
    color white
    animation-name buttonHover
    animation-duration 0.1s
    animation-fill-mode forwards

.anim-btn--unhover
    animation-name buttonUnhover
    animation-duration 0.1s
    animation-fill-mode forwards
