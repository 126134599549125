.form
	position relative
	display flex
	overflow visible

	&::before
		content ''
		overflow hidden
		display block

.form__videos
	position fixed
	bottom 0
	top 150pr
	z-index -1
	+portrait()
		display none

.form__form
	position relative
	display flex
	width 100%
	min-height calc(100vh - 150pr)
	max-width 925pr
	margin-left 157pr
	margin-right 40pr
	padding 77pr 59pr
	flex-wrap wrap
	align-content flex-start
	background black
	+portrait()
		height auto
		width 100%
		margin 0
		padding 30pr
		background transparent

.form__title
	width 100%
	font-size 30pr
	font-weight 500
	letter-spacing -0.3pr
	+portrait()
		font-size: 20pr
		line-height: 25pr
.form__part
	width 318pr
	margin-right 20pr
	margin-bottom 5pr
	padding 10pr 20pr 16pr
	background dark_gray
	+portrait()
		margin 0 0 6pr
		width 100%

.form__label
	display block
	margin-bottom 6pr
	font-size 15pr
	line-height 23pr
	color label_color

.form__input
	@extends $input-reset
	font-size 20pr
	line-height 23pr
	color white

.form__checkbox
	position absolute
	bottom 190pr
	display block
	opacity 0
	+portrait()
		bottom 150pr

.form__check-label
	position relative
	width 100%
	margin 13pr 0 19pr
	padding-left 30pr
	font-size 15pr
	line-height 23pr
	color label_color
	cursor pointer
	+portrait()
		line-height 17pr

	&::before
		content ''
		position absolute
		top 2pr
		left 0
		display block
		width 20pr
		height 20pr
		border 1pr solid label_color

	&::after
		content ''
		position absolute
		top 7pr
		left 5pr
		display none
		width 10pr
		height 10pr
		background gold
	
	a
		color #d3ac6b
		text-decoration none
		&:hover
			text-decoration underline

.form__checkbox:checked ~ .form__check-label::after
		display block

.form__popup
		position absolute
		top 0
		left 0
		display none
		width 100%
		height 100%

.form__popup-content
		width auto
		height auto
		margin auto
		padding 0pr 40pr 16pr
		background gold
		cursor pointer
		transition 0.2s
		transition-property opacity
		+portrait()
				width 100%
				margin-bottom 0
				padding 10pr 30pr 10pr

		&:hover
				opacity 0.9


.form__popup--open
		display flex

.form__popup-title
		font-size 30pr
		font-weight 500
		line-height 35pr
		letter-spacing -0.3pr
		+portrait()
				font-size 20pr
				line-height 25pr

.form__submit
	width 318pr
	+portrait()
		width 100%

.form__close
	position absolute
	top 0
	right -40pr
	display block
	width 80pr
	height 80pr
	background white
	background-image url('/static/img/close.svg')
	background-repeat no-repeat
	background-size 36pr 36pr
	background-position center
	+portrait()
		position fixed
		right 0
		top 10pr
		background-color #000
		z-index 10001
.form__close--mobile
	display none
	+portrait()
		display block


.page--form
	+portrait()
		background #000
		.header
			background #000
		.main
			position relative
			//z-index 1001
