.counselor-card
	padding 0 30pr 58pr
	position relative
	transition 0.2s
	transition-property padding
	+portrait()
		padding 0
	//background linear-gradient(to bottom, transparent 206pr, html_bg 206pr)
	&:before
		content ''
		display block
		position absolute
		width 100%
		height calc(100% - 206pr)
		top 206pr
		left 0
		transform-origin left top
		background html_bg
		z-index -1
		+portrait()
			display none

.counselor-card__image
	background ligth_gray
	width 250pr
	height auto
	+portrait()
		width 60%

.counselor-card__name
	margin 19pr 0 16pr
	font-size 30pr
	line-height 35pr
	font-weight 500
	white-space pre-line
	+portrait()
		font-size: 20pr
		line-height: 25pr
		letter-spacing: -0.2pr
		white-space normal

.counselor-card__desc
	margin 10pr 0 0
	font-size 15pr
	line-height 23pr

.counselor-card__year
	font-size 15pr
	line-height 23pr
	color gold

.counselor-card__button
	position absolute
	left 30pr
	bottom 41pr
	display flex
	justify-content center
	align-items center
	width 169pr
	height 41pr
	margin-top auto
	font-size 15pr
	text-decoration none
	color #ffffff
	border 1pr solid #D3AC6B
	border-radius 20pr
	transform scaleY(0)
	transform-origin 0% 100%
	transition 0.2s
	transition-property backgroud-color, transform
	+portrait()
		display none
	
	&:hover
		background #D3AC6B

.counselor-card:hover
	padding-bottom 108pr
	+portrait()
		padding 0
	.counselor-card__button
		transform scaleY(1)

