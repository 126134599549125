.home__slider
	height 100vh
	position relative
	+portrait()
		height auto
		padding-top 114pr
.points
	position absolute
	bottom 80pr
	left 58pr
	display flex
	align-items center
	justify-content center
	margin 0
	padding 0
	z-index 5000
	list-style none
	+portrait()
		left 0
		bottom 0
		padding 25pr 30pr
		background #000
		height 54pr
		width 40%
		position fixed

	li
		margin-right 16pr
		opacity 0
		//height 5pr
		line-height 1


		button
			width 3px
			height 3px
			background #D3AC6B
			padding 0
			border none
			border-radius 50%
			display block


		&.active
			button
				width 5px
				height 5px
				background #fff


.home__slide
	position absolute
	top 0
	left 0
	height 100%
	width 100%
	padding-top 150pr
	z-index 0
	overflow hidden

	+portrait()
		padding-top 0
		margin-bottom 50pr
		position relative
		top auto
		height auto

		&.services__block
			margin-bottom 0

	//opacity 0
	&.animated, &.prev
		opacity 1
	&.animated
		z-index 10
	//&.prev, &.next
	//	z-index 0

	&.-first:after
		content ''
		display block
		height 70pr
		width 1pr
		background #D3AC6B
		position absolute
		bottom 60pr
		left 226pr
		transition 0.5s 1s
		transform scale(1, 0)
		transform-origin top left
		+portrait()
			display none

	&.case--open
		+portrait()
			position relative
			z-index 10


.slide__video
	width 56%
	overflow hidden
	height 100vh
	position relative
	z-index 1
	+portrait()
		top -50pr
		width 100%
		height auto
	.animation__body
		height 100%
		+portrait()
			height auto
			min-height calc((100vw / 100) * 56)
	&.animation__wrapper
		position relative
		transition 1s
		max-width 100%

		&:after
			content ''
			display block
			position absolute
			width 100%
			height 100%
			transform scale(1, 1)
			transform-origin right top
			right 0
			top 0
			background #171717
			z-index 10
			transition 1s
			+portrait()
				display none
		&:before
			content ''
			display block
			position absolute
			width 100%
			height 100%
			transform scale(0, 1)
			transform-origin left top
			left 0
			top 0
			background #171717
			z-index 10
			transition 1s
			+portrait()
				display none


	&.-big
		width calc(100% - 310pr)
		+portrait()
			width 100%
			height auto
	&.-right
		margin-right 0
		margin-left auto

	&--all-services
		+portrait()
			margin-bottom -50pr

	video
		width 100%
		height 100%
		object-fit cover
		vertical-align top

.home__slide.-first
	.slide__video
		video
			object-position: left


.links__slider
	position relative
	z-index 1000
	
	&.-mobile
		display none
		+portrait()
			display block

.links__slider_desc
	position relative
	z-index 1000
	&.-desktop
		+portrait()
			display none

.slide__text
	position absolute
	top 224pr
	left 160pr
	width 550pr
	padding 38pr 50pr 35pr
	color #000
	z-index 10
	//overflow hidden
	+portrait()
		position relative
		width 90%
		left 10%
		top 0

	&.animation__wrapper
		&:after
			content ''
			display block
			position absolute
			width 100%
			height 100%
			transform scale(0, 1)
			transform-origin left top
			right 0
			top 0
			background #fff
			z-index 1
			transition 1s
			+portrait()
				transform none

	.animation__body
		position relative
		z-index 10
		opacity 0
		transition 0.7s 1s
		+portrait()
			opacity 1

	&.-right
		left auto
		right 0
		top 264pr
		+portrait()
			padding 30pr
			position relative
			top -60pr
			left 8%
			right auto
			width 84%

.slide__links
	position absolute
	margin-top 40pr
	width 100%
	max-width 44%
	float left
	z-index 10
	height 100%
	max-height 420pr
	transition 1s
	+portrait()
		position relative
		width 80%
		max-width 80%
		z-index 1001
		max-height none
		height auto
		margin-top 0

	.slick-arrow
		opacity 0
		transition 0.6s 2s
		+portrait()
			opacity 1
			bottom -10pr

			&.slick-next
				right -20pr
			&.slick-prev
				right 80pr


	.animation__body
		padding 55pr 58pr
		padding-right 16pr
		opacity 0
		position relative
		z-index 2
		transition 0.7s 1s
		+portrait()
			padding 30pr
	&.animation__wrapper
		&:after
			content ''
			display block
			position absolute
			width 100vw
			height 100%
			transform scale(0, 1)
			transform-origin left top
			left 0
			top 0
			background #000
			max-height 420pr
			z-index 1
			transition 1s
			+portrait()
				width 100%
				transform scale(1, 1)
				max-height none
	.button
		position absolute
		right -70pr
		top 100%
		cursor pointer
		+portrait()
			display none
.services__block
	.slide__video.-right
		+portrait()
			top -100pr

.links__title
	margin-bottom 33pr
	font-style: normal;
	font-weight: 500;
	font-size: 30pr;
	line-height: 35pr;
	letter-spacing: -0.3pr;
	+portrait()
		font-size: 20pr;
		line-height: 25pr;

.title
	font-weight: 500;
	font-size: 30pr;
	line-height: 35pr;
	letter-spacing: -0.3pr;
	+portrait()
		font-size: 20pr;
		line-height: 25pr;

	&--home
		p
			margin-top 2pr
.links__wrapper
	position relative
	width 100%
	height 200pr
	overflow hidden
	+portrait()
		height auto

ul.links
	padding 0
	margin  0
	display flex
	width calc(100vw - 7.25rem)
	min-height 320pr
	left 0
	position absolute
	//flex-direction column
	align-items flex-start
	justify-content flex-start
	flex-wrap wrap
	align-content flex-start
	list-style none
	font-size: 15pr
	line-height: 23pr
	+portrait()
		width 100%
		min-height 100pr
		padding-bottom 66pr
		position relative
	li
		margin 14pr 0
		padding-right 42pr
		width 22%
		height 91pr
		max-height 70pr
		position relative
		transition 0
		+portrait()
			width 100%
			height auto
			max-height 100pr

		&:nth-child(3), &:nth-child(4)
			opacity 0
			+portrait()
				opacity 1

		&:after
			content ''
			position absolute
			left 0
			bottom 0
			width 25pr
			height 1px
			background #D3AC6B
			display none

		a
			color #fff
			text-decoration none
			&:hover
				color #D3AC6B

.links__slide.--second
	li:nth-child(3), li:nth-child(4)
		opacity 1
	li:nth-child(1), li:nth-child(2), li:nth-child(5), li:nth-child(6)
		display none

.links__close
	position absolute
	top 150pr
	right 10%
	z-index 100


.links--open, .links--open.animated
	.slide__links
		max-width 90%
		min-height 500pr
		max-height none
		height calc(100vh - 190px)
		overflow hidden

		&.animation__wrapper
			&:after
				transform scale(0.9, 1)
				max-height 100%

	.links__wrapper
		height 360pr
	.slick-slide
		opacity 0

	.slick-slide.slick-current
		opacity 1
		width 100vw!important
	.slick-arrow, .button--gold
		opacity 0!important
		transition 0.6s 0s!important
.hide-slides
	.slick-slide
		opacity 0
	.slick-slide.slick-current
		opacity 1
		width 100vw!important


.links--open
	ul.links
		align-items stretch
		li
			max-height none
			height auto
			padding-bottom 24pr
			transition 0.8s 0.3s

			&:after
				display block
			&:nth-child(3), &:nth-child(4)
				opacity 1

			&:nth-child(5), &:nth-child(6)
				&:after
					display none

	.--second
		li:nth-child(1), li:nth-child(2), li:nth-child(5), li:nth-child(6)
			display block

.home_cases__slider
	z-index 10
	p
		margin-top 18pr
	.slick-arrow
		top calc(100% + 8pr)

	.slick-next
		right 50pr
	.slick-prev
		right 150pr
	.slick-slide
		opacity 1!important
		display block!important
	.slick-list
		overflow-x hidden

.home_cases__slide
	opacity 0
	transform translateX(10%)
	transition opacity 0.6s  , transform 0.6s

.home_cases__link
	display block
	padding 38pr 50pr 35pr
	text-decoration none
	color #000000
	+portrait()
		padding 30pr

.home_case__title_link
	color #000000
	text-decoration none

.case__close--home
	position absolute
	left calc(100% - 9.5rem)
	top 264pr
	z-index 100
	transition 1s 1s
	max-width 80pr
	+portrait()
		position fixed
		top 15pr
		z-index 10003
		right 85pr
		left auto

.inner-animation
	.slide__text.animation__wrapper:after
		transition 1s 1s
	.slide__text .animation__body
		transition 1s 1.7s
.case__full
	position absolute
	top 264pr
	left calc((100% - 41.25rem)/2)
	transition 1s
	width 660pr
	z-index 10
	+portrait()
		width 100%
		left 0
		top 77pr

	.case__inner
		padding 50pr
		position relative
		z-index 10
		color #000
		opacity 0
		transition 0.3s 0s

	&:after
		display block
		content ''
		position absolute
		transform-origin left top
		transform scale(0, 1)
		top 0
		left 0
		background #fff
		width 100%
		height 100%
		z-index 1
		transition 1s 0s

.case--open
	&.animated
		.case__full
			.case__inner
				opacity 1
				transition 1s 1.7s

			&:after
				transform scale(1, 1)
				transition 1s 1s


		.slide__text.animation__wrapper:after
			transform scale(1, 0)
			transition 1s 0s
		.slide__text .animation__body
			opacity 0
			transition 0.3s 0s

	&.prev, &.next
		.button--close
			max-width 0
			transition 1s 0s

		.case__full
			.case__inner
				opacity 0
				transition 0.3s 0s

			&:after
				transform scale(0, 1)
				transition 1s 0s

		+portrait()
			.button--close
				max-width 80pr
				transition 1s 1s
			.case__full
				.case__inner
					opacity 1
					transition 1s 1.7s

				&:after
					transform scale(1, 1)
					transition 1s 1s


			.slide__text.animation__wrapper:after
				transform scale(1, 0)
				transition 1s 0s
			.slide__text .animation__body
				opacity 0
				transition 0.3s 0s



.slick-current + .slick-slide
	.home_cases__slide
		transform translateX(-10%)

.slick-active
	.home_cases__slide
		opacity 1
		transform translateX(0)
		transition opacity 0.5s  0.1s, transform 0.6s 0.1s
.slider__counter
	position absolute
	top -80pr
	right 15pr
	color #fff
	font-size 11pr
	+portrait()
		bottom -50pr
		top auto
		left 0
		font-size 13pr
.home_cases__arrows
	position absolute
	top calc(100%)
	right 0
	width 310pr
	height 80pr
	display flex
	justify-content center
	align-items center

	&:before
		content ''
		display block
		background #000
		position absolute
		top 0
		left 0
		width 100%
		height 100%
		transform-origin right top
		transform scale(1, 0)
		transition 0.6s 1s
		+portrait()
			display none

a.home_cases__read
	display block
	margin-top 0
	color #000
	text-decoration none
	opacity 0
	transition 0.2s
	&:hover
		color #d3ac6b

.slick-active a.home_cases__read
	opacity 1
	transition 0.5s 0.7s

.slide--cases
	.slide__text
		padding 0
	+portrait()
		padding-bottom 100pr
		display flex
		flex-direction column
		.slide__text
			padding 0
			order 1
			top -50pr
			width 80%
			left 10%

		.slide__video
			order 0
			top 0


.all-counselors__slider
	position relative
	z-index 1
	display none
	+portrait()
		display block

.home__counselors
	position absolute
	top 0
	left 10%
	height 100%
	width 960pr
	.counselors__button
		opacity 0
		+portrait()
			opacity 1
	+portrait()
		padding-bottom 39pr
		position relative
		left 0
		width 100%
	&:after
		content ''
		display block
		position absolute
		bottom 0
		background #000
		height calc(100% - 334pr)
		width 100%
		z-index 1
		transform-origin top left
		left 0
		transform scale(0, 1)
		transition 1s
		+portrait()
			height calc(100% - 120pr)
			top 120pr
			z-index 0
	.counselor-card
		+portrait()
			padding 0 40pr 0 0
		h3, p, span
			opacity 0
			transition 1s 2s

		&__img_wrapper
			max-width 0
			overflow hidden
			transition 1s 1s
			transform-origin top left


			img
				width 250pr
				height auto
				+portrait()
					width 60%
		&:before
			transition 1s 1s
			transform-origin top left
			transform scale(0, 1)
	&__scroll
		overflow hidden
		height 100%
		+portrait()
			display none
		.all-counselors
			margin-top 150pr
			margin-bottom 50px
			z-index 100
			transition 1s
			+portrait()
				margin-top 0

			&__list
				margin 0
				background transparent
				+portrait()
					padding 15pr


.counselors__close
	margin 0
	position absolute
	right -40pr
	top 150pr
	z-index 100
	max-width 80pr
	overflow hidden
	transition 0.5s

	&.hidden
		max-width 0

.slide--counselors
	.slick-prev
		display none!important
	.slick-arrow
		bottom auto
		top 25pr
		right 47pr
	.slide__video
		+portrait()
			display none
.home__contact
	position fixed
	bottom 0
	right 0
	margin-right 0
	margin-left auto
	width 60%
	display none
	z-index 5000
	+portrait()
		display block
	.button
		width 100%
		display inline-block
		color #fff
		span
			display inline-block
			line-height 54pr
			height 54pr
			width 100%
			padding 0 7pr
			text-align center



.animated
	.counselors__button
		opacity 1
	&.home__slide
		&.-first:after
			transform scale(1,1)

	.slide__text
		.slick-arrow
			opacity 1
		&.animation__wrapper
			&:after
				transform scale(1, 1)
		.animation__body
			opacity 1

	.slide__links
		.slick-arrow
			opacity 1
		&.animation__wrapper
			&:after
				transform scale(0.44 , 1)
				+portrait()
					transform scale(1 , 1)
		.animation__body
			opacity 1

	.slide__video
		&.animation__wrapper
			&:before
				transform scale(0, 1)
				//transition 0
			&:after
				transform scale(0, 1)

	.home_cases__arrows
		&:before
			transform scale(1, 1)

	.home__counselors
		&:after
			transform scale(1, 1)
		.counselor-card
			h3, p, span
				opacity 1
			&__img_wrapper
				max-width 100%
			&:before
				transform scale(1, 1)
.prev
	&.home__slide
		&.-first:after
			transition 0.5s 0s
			transform scale(1,0)
			+portrait()
				transform none
	.slide__text, .slide__links
		.animation__body
			opacity 0
			transition 0.3s 0s
			+portrait()
				opacity 1
		&.animation__wrapper
			&:after
				transform scale(1, 0)
				transition 1s 0s
				+portrait()
					transform none
	.slide__video

		&.animation__wrapper
			&.animated__width
				max-width 0
				+portrait()
					max-width 100%
				&:before
					display none
			&:after
				transform scale(0,1)

			&:before
				transform scale(1,1)
				+portrait()
					transform scale(1,0)

	.home_cases__arrows
		&:before
			transform-origin right top
			transform scale(0, 1)
			transition 0.6s 0s
			+portrait()
				transform none

	&.animated .slide__links.animation__wrapper:after, .slide__links.animation__wrapper:after
		transform scale(1, 0)
		+portrait()
			transform scale(1, 1)
.next
	&.home__slide
		&.-first:after
			transition 0.5s 0s
			transform scale(1,0)
			+portrait()
				transform none
	.slide__links.animation__wrapper:after
		transition 1s 0s
		transform scale(0, 1)
		+portrait()
			transform none
	.slide__links .animation__body
		opacity 0
		transition 0.3s 0s
		+portrait()
			opacity 1
	.slide__video.animation__wrapper:before
		transform: scale(1, 1);
		transition 1s 0s
		+portrait()
			transform scale(1, 1)
	.slide__video.animation__wrapper:after
		transform: scale(0, 1);
		//transition 1s 0s

	.slide__text, .slide__links
		.animation__body
			opacity 0
			transition 0.3s 0s
			+portrait()
				opacity 1
		&.animation__wrapper
			&:after
				transform scale(1, 0)
				transition 1s 0s
				+portrait()
					transform scale(1, 1)


	.home__counselors
		&:after
			transform: scale(0, 1);
			transition 1s 0s
			+portrait()
				transform none

		.counselor-card
			.counselor-card__img_wrapper
				max-width 0
				transition 1s 0s
				+portrait()
					max-width 100%
			&:before
				transform scale(0, 1)
				transition 1s 0s
				+portrait()
					transform scale(1, 1)
			p, h3, span
				opacity 0
				transition 0.3s 0s
				+portrait()
					opacity 1
.slide__video .animation__body
	background-size cover
	video
		display none
.loaded
	.slide__video .animation__body
		video
			display block
	.points
		li
			opacity 1
		li:nth-child(1)
			transition 0.5s 0s
		li:nth-child(2)
			transition 0.5s 0.5s
		li:nth-child(3)
			transition 0.5s 1s
		li:nth-child(4)
			transition 0.5s 1.5s


.initial

	.slide__text.animation__wrapper:after,
	.slide__video.animation__wrapper:before,
	.slide__video.animation__wrapper:after
		transition-delay 0s!important

.points
	display none
	+portrait()
		display flex

.page--index
	.points
		display flex