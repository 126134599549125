.slick-dots
	position absolute
	bottom 80pr
	left 58pr
	list-style none
	display flex
	margin 0
	padding 0

	button
		font-size 0
		text-indent 100px
		width 3pr
		height 3pr
		border none
		background #D3AC6B
		border-radius 50%
		padding 0
		margin 8pr

	.slick-active
		button
			width 5pr
			height 5pr
			background #fff

.slick-arrow
	position absolute
	width 100pr
	height 60pr
	top 90%
	background url('/static/img/next.svg')  center no-repeat
	background-size 50pr 10pr
	font-size 0
	text-indent 1000px
	overflow hidden
	border none
	+portrait()
		top auto
		bottom 0
.slick-next
	right 74pr

.slick-prev
	right 174pr
	z-index 1
	transform scale(-1, 1)
